import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { DOWNLOAD_URL } from "../utils/constants";
import logo from '../logo.png'

interface HeroSectionProps { }

const HeroSection: FunctionComponent<HeroSectionProps> = () => {
  return (
    <Container maxW="container.lg">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Container maxW="container.md" textAlign="center">
            <Heading size="2xl" mb={4} color="gray.700">
              Effortlessly compose emails with AICompose
            </Heading>

            <Text fontSize="xl" color="gray.500">
              Whether you're a busy professional or a student, you know how
              important it is to have a streamlined email workflow. That's where
              AICompose comes in. Our browser extension allows you to compose
              emails quickly and easily, without sacrificing quality. From
              business proposals to friendly catch-ups, AICompose has you
              covered. Join the thousands of users who have already simplified
              their email workflow with AICompose – try it out today!
            </Text>

            <Button mt={8} bg="#0c2840" color="#fff" _hover={{ bg: "#061421" }} onClick={() => window.open(DOWNLOAD_URL, "_blank")}>
              <Image src={logo} alt="Logo" boxSize="25px" mr={2} />
              Download Now
            </Button>
          </Container>
        </VStack>
      </Center>
    </Container>
  );
};
export default HeroSection;
