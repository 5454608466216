import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import prodFirebaseConfig from "./config/firebase.config.prod.json";
import devFirebaseConfig from "./config/firebase.config.dev.json";

// Firebase configuration variables loaded from environment variables
const firebaseConfig = process.env.REACT_APP_DEV_MODE === "true" ? devFirebaseConfig : prodFirebaseConfig;
// If Firebase isn't already initialized, initialize it using the above credentials.
firebase.initializeApp(firebaseConfig);

export default firebase;
