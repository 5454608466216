import { useState } from "react";
import Profile from "./Profile";
import ProfileEdit from "./ProfileEdit";

function ProfileCard() {
  const [isEditClicked, setIsEditClicked] = useState(false);

  return isEditClicked ? (
    <ProfileEdit onLoginClick={() => setIsEditClicked(false)} />
  ) : (
    <Profile onLoginClick={() => setIsEditClicked(true)} />
  );
}
export default ProfileCard;
