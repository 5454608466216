import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import HeroSection from "./HeroSection";
import Pricing from "./Pricing";
import FAQSection, { FAQType } from "./FAQSection";
import CTA from "./CTA";

export interface HighlightType {
  icon: string;
  title: string;
  description: string;
}

const highlights: HighlightType[] = [
  {
    icon: "🚀",
    title: "Streamline Email Generation",
    description:
      "Our browser extension streamlines the process of generating emails in Gmail, saving you time and increasing efficiency.",
  },
  {
    icon: "📝",
    title: "Customizable Email Templates",
    description:
      "With our extension, you can customize email templates with your own text, formatting, and branding, so you can create emails that reflect your personal style and tone.",
  },
  {
    icon: "💸",
    title: "Affordable Pricing",
    description:
      "Our subscription service is currently priced inexpensively, making it an affordable option for businesses of all sizes.",
  },
  {
    icon: "🔒",
    title: "Secure Data Transmission",
    description:
      "Your personal information is safe with our extension. We use secure data transmission protocols and do not store any of your personal data.",
  },
];

const faqs: FAQType[] = [
  {
    q: "What is this browser extension for?",
    a: "This extension is designed to help you generate emails more quickly and efficiently in Gmail.",
  },
  {
    q: "How does the extension work?",
    a: "The extension adds a new button to the Gmail interface that appears in the compose text area when you open it. You can use all the formatting and editing features of Gmail to compose your email. The extension then reads the details from the compose text area and uses this information to generate a new email in your Gmail inbox.",
  },
  {
    q: "Is the extension free?",
    a: "The basic version of the extension is free to use, but it limits users to generating a maximum of 5 emails per day. There is also a subscription service that offers additional features and allows for unlimited email generation. Currently, the subscription service is priced inexpensively but may become more expensive as the product evolves.",
  },
  {
    q: "Is there any support available if I have questions or problems with the extension?",
    a: "Yes, you can contact the developer for support if you have any questions or problems with the extension.",
  },
  {
    q: "How do I sign up to receive exclusive offers?",
    a: "To sign up for exclusive offers and updates about the extension, visit our website and enter your email address in the sign-up form. You'll be the first to know about new features and promotions, and you may be eligible for exclusive discounts and deals.",
  },
  {
    q: "Who do I contact for customer support?",
    a: "Please contact us at aicomposexyz@gmail.com",
  },
];

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AICompose | Streamline Your Email Game</title>
      </Helmet>

      <Box bg="gray.50">
        <HeroSection />
        <Container id="features" maxW="container.md" centerContent py={[8, 28]}>
          <SimpleGrid spacingX={10} spacingY={20} minChildWidth="300px">
            {highlights.map(({ title, description, icon }, i: number) => (
              <Box p={4} rounded="md" key={`highlight_${i}`}>
                <Text fontSize="4xl">{icon}</Text>

                <Text fontWeight={500}>{title}</Text>

                <Text color="gray.500" mt={4}>
                  {description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Container>

        <Container py={28} maxW="container.lg" w="full" id="pricing">
          <Pricing />
        </Container>

        <Container py={28} maxW="container.md">
          <Box w="full">
            <VStack spacing={10} w="full">
              <Text fontWeight={500} fontSize="2xl" align="center">
                Frequently asked questions
              </Text>
              <FAQSection items={faqs} />
            </VStack>
          </Box>
        </Container>

        <CTA
          heading={`Get started with AICompose today!`}
          cta={{ name: "I want this!", link: "#" }}
        />

        <Container maxW="container.lg">
          <Flex py={6}>
            <Box>
              <Text>© 2023 AICompose</Text>

              <Text>Made by AICompose</Text>
            </Box>
            <Spacer />

            {/* <LinkBox>
              <LinkOverlay href="https://twitter.com/@thisissukh_" isExternal>
                <Image src="twitter.svg" alt="Twitter logo"></Image>
              </LinkOverlay>
            </LinkBox> */}
          </Flex>
        </Container>
      </Box>
    </>
  );
};
export default LandingPage;
