import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  FormErrorMessage,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaGoogle } from "react-icons/fa";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebaseClient";
import { useNavigate } from "react-router-dom";
import { checkBrowserExtInstalled, onLogin } from "../stripe/helpers";
import PrivacyPolicy from "../components/PrivacyPolicy";
import { DOWNLOAD_URL } from "../utils/constants";

interface Props {
  onLoginClick: () => void;
}

function SignupCard({ onLoginClick }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const handleGoogleSignup = async () => {
    try {
      if (!(await checkBrowserExtInstalled())) {
        onOpen();
        return;
      }
      const provider = new GoogleAuthProvider();
      const userCredentials = await signInWithPopup(auth, provider);
      const user = userCredentials.user;
      await onLogin(user, user.displayName);

      if (user) {
        navigate("/#pricing");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (!(await checkBrowserExtInstalled())) {
        onOpen();
        return;
      }
      const { firstName, lastName, email, password } = data;
      const name = firstName + " " + lastName;
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await onLogin(user, name);

      if (user) {
        navigate("/");
      }
    } catch (err) {
      setErrors(true);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Extension</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Browser Extension must be installed to continue.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              // TODO change to download page
              onClick={() => window.open(DOWNLOAD_URL, "_blank")}
            >
              Proceed to Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign up
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool features ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit(onSubmit)} data-testid="signup-form">
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <Controller
                    rules={{
                      maxLength: 40,
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        id="firstName"
                        isRequired
                        isInvalid={!!error}
                      >
                        <FormLabel>First Name</FormLabel>
                        <Input type="text" {...field} />
                        {error && (
                          <FormErrorMessage>{error.message}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                    name="firstName"
                    control={control}
                  />
                </Box>
                <Box>
                  <Controller
                    rules={{
                      maxLength: 40,
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl id="lastName">
                        <FormLabel>Last Name</FormLabel>
                        <Input type="text" {...field} />
                      </FormControl>
                    )}
                    name="lastName"
                    control={control}
                  />
                </Box>
              </HStack>
              <Controller
                rules={{
                  maxLength: 40,
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" {...field} />
                  </FormControl>
                )}
                name="email"
                control={control}
              />
              <Controller
                rules={{
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                    message:
                      "Password must include at least one lowercase letter, one uppercase letter, one number, and one symbol",
                  },
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl id="password" isRequired isInvalid={!!error}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        {...field}
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {error && (
                      <FormErrorMessage>{error.message}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
                name="password"
                control={control}
              />
              {errors && (
                <Text color="red">Something went wrong. Please try again.</Text>
              )}
              <PrivacyPolicy />
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                  disabled={!isValid}
                >
                  Sign up
                </Button>
                <Button
                  size="lg"
                  colorScheme="blue"
                  variant="solid"
                  leftIcon={<FaGoogle color="white" />}
                  onClick={handleGoogleSignup}
                >
                  Sign up with Google
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link onClick={onLoginClick} color={"blue.400"}>
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SignupCard;
