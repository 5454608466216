import React from "react";
import { Route, Routes } from "react-router-dom";

import LandingPage from "./components/LandingPage";
import AuthCard from "./auth/AuthCard";
import ProfileCard from "./profiles/ProfileCard";
import Privacy from "./privacy/Privacy";
const AppRoutes = (): JSX.Element => {
  return (
    <>
      <Routes>
        {/* Fallback route */}
        {/* <Route path="*" element={<PageNotFound />} />
        <Route path="/500" element={<InternalServer />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<AuthCard />} />
        <Route path="/profile" element={<ProfileCard />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
