import { useState } from "react";
import {
  Checkbox,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

function PrivacyPolicy() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCheckboxChange = (e: any) => {
    setIsOpen(e.target.checked);
  };

  return (
    <>
      <Checkbox colorScheme="green" onChange={handleCheckboxChange}>
        I have read and agree to the privacy document
      </Checkbox>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalBody>
            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Introduction
            </Heading>
            <Text mb={4}>
              At AICompose, we are committed to protecting your privacy and
              ensuring that your personal information is handled in a
              responsible and transparent manner. This Privacy Policy explains
              how we collect, use, and share your personal information when you
              use our browser extension.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Consent
            </Heading>
            <Text mb={4}>
              By using AICompose, you consent to our Privacy Policy and agree to
              our Terms of Use.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Information Collection
            </Heading>
            <Text mb={4}>
              When you sign up for AICompose, we collect your First Name, Last
              Name, Email, and Password. You can also sign up using Google Auth,
              in which case we collect your display name and email from your
              Google account. We store this information securely in Firebase
              Authentication and Firestore.
              <br />
              When you use AICompose, we access your use of the compose field in
              Gmail. This information is used to generate emails for you, and is
              not stored in any database.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Use of Information
            </Heading>
            <Text mb={4}>
              We use the information we collect to provide and improve AICompose
              and to personalize your experience. Specifically, we may use your
              information to provide you with personalized content and
              recommendations, develop and improve AICompose, analyze user
              behavior and usage patterns to improve the user experience, and
              communicate with you about AICompose and our other products and
              services.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Sharing of Information
            </Heading>
            <Text mb={4}>
              We do not share your personal information with any third parties,
              except as described below:
              <ul>
                <li>
                  We use the Firebase Stripe payment extension to manage
                  subscriptions. When you subscribe, your subscription
                  information is stored securely in Firebase and Stripe.{" "}
                </li>
                <li>
                  We may disclose your personal information if required to do so
                  by law or in the good faith belief that such action is
                  necessary to comply with legal obligations, protect and defend
                  our rights or property, or to protect the personal safety of
                  users of AICompose or the public.
                </li>
              </ul>
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Security
            </Heading>
            <Text mb={4}>
              We take reasonable steps to protect your personal information from
              unauthorized access, use, or disclosure. However, no method of
              transmission over the internet or electronic storage is 100%
              secure, and we cannot guarantee the security of your information.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Choices
            </Heading>
            <Text mb={4}>
              You have certain choices about how we collect, use, and share your
              personal information. For example, you can opt out of receiving
              marketing emails from us by clicking the "unsubscribe" link in the
              email, and change your browser settings to disable or limit
              AICompose's access to certain aspects of the website or the
              compose field in Gmail.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Updates
            </Heading>
            <Text mb={4}>
              We may update this Privacy Policy from time to time. Any changes
              will be effective immediately upon posting the updated Privacy
              Policy on our website. Your continued use of AICompose after the
              updated Privacy Policy has been posted will constitute your
              acceptance of the changes.
            </Text>

            <Heading as="h3" textAlign={"center"} size="md" mb={2}>
              Contact Us
            </Heading>
            <Text mb={4}>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at aicomposexyz@gmail.com.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrivacyPolicy;
