import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
  Center,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseClient";
import { fetchSubscriptionId } from "../stripe/helpers";

interface Props {
  onLoginClick: () => void;
}

export default function UserProfileEdit({ onLoginClick }: Props): JSX.Element {
  const handleSubmit = () => {
    onLoginClick();
  };
  const toast = useToast();

  const handleCancel = () => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        const token = await user.getIdToken();

        const subscriptionId = await fetchSubscriptionId(user.uid);
        if (subscriptionId) {
          try {
            await axios.delete(
              `${process.env.REACT_APP_SERVER_URL}/subscriptions/${subscriptionId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            toast({
              title: "Subscription Cancelation Succeeded",
              description: "We are sorry to see you go :(",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onLoginClick();
          } catch (err) {
            toast({
              title: "Cancelation Failed",
              description:
                "We failed to cancel your subscription. Try again later, or contact customer support.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
        onLoginClick();
      }
    });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
          User Profile Edit
        </Heading>
        <FormControl id="userName">
          <FormLabel>User Icon</FormLabel>
          <Stack direction={["column", "row"]} spacing={6}>
            <Center>
              <Avatar
                size="xl"
                src="https://media.istockphoto.com/id/811610402/photo/portrait-silhouette.jpg?s=612x612&w=0&k=20&c=0XyzoNtp_D2IjSc9WvIfwShkM0EumppV5q97f8ld-j4="
              ></Avatar>
            </Center>
          </Stack>
        </FormControl>
        <FormControl id="userName" isRequired>
          <FormLabel>User name</FormLabel>
          <Input
            placeholder="UserName"
            _placeholder={{ color: "gray.500" }}
            type="text"
          />
        </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: "gray.500" }}
            type="email"
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            placeholder="password"
            _placeholder={{ color: "gray.500" }}
            type="password"
          />
        </FormControl>
        <Stack spacing={6} direction={["column", "row"]}>
          <Button
            bg={"red.400"}
            color={"white"}
            w="full"
            _hover={{
              bg: "red.500",
            }}
            onClick={handleCancel}
          >
            Cancel Subscription
          </Button>
        </Stack>
        <Stack spacing={6} direction={["column", "row"]}>
          <Button
            bg={"red.400"}
            color={"white"}
            w="full"
            _hover={{
              bg: "red.500",
            }}
            onClick={onLoginClick}
          >
            Cancel
          </Button>
          <Button
            bg={"blue.400"}
            color={"white"}
            w="full"
            _hover={{
              bg: "blue.500",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
