import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";

import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebaseClient";
import { useNavigate } from "react-router-dom";
import { checkBrowserExtInstalled, onLogin } from "../stripe/helpers";
import { DOWNLOAD_URL } from "../utils/constants";

interface Props {
  onLoginClick: () => void;
}
export default function LoginCard({ onLoginClick }: Props) {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleGoogleSignup = async () => {
    try {
      if (!(await checkBrowserExtInstalled())) {
        onOpen();
        return;
      }
      const provider = new GoogleAuthProvider();
      const userCredentials = await signInWithPopup(auth, provider);
      const user = userCredentials.user;
      await onLogin(user, user.displayName);
      navigate("/#pricing");
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    if (!(await checkBrowserExtInstalled())) {
      onOpen();
      return;
    }
    try {
      const { email, password } = data;
      const { user } = await signInWithEmailAndPassword(auth, email, password);

      await onLogin(user, user.displayName);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Extension</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Browser Extension must be installed to continue.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => window.open(DOWNLOAD_URL, "_blank")}
            >
              Proceed to Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool{" "}
            <Link href={`/#features`} color={"blue.400"}>
              features
            </Link>{" "}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit(onSubmit)} data-testid="login-form">
            <Stack spacing={4}>
              <Controller
                rules={{
                  maxLength: 40,
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" {...field} />
                    {error && (
                      <FormErrorMessage>{error.message}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
                name="email"
                control={control}
              />
              <Controller
                rules={{
                  maxLength: 40,
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input type="password" {...field} />
                    {error && (
                      <FormErrorMessage>{error.message}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
                name="password"
                control={control}
              />

              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox>Remember me</Checkbox>
                  <Link color={"blue.400"}>Forgot password?</Link>
                </Stack>
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                  disabled={!isValid}
                >
                  Sign in
                </Button>
                <Button
                  size="lg"
                  colorScheme="blue"
                  variant="solid"
                  leftIcon={<FaGoogle color="white" />}
                  onClick={handleGoogleSignup}
                >
                  Sign in with Google
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  Don't have an account?{" "}
                  <Link onClick={onLoginClick} color={"blue.400"}>
                    Signup
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
