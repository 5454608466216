import React from "react";

import AppRoutes from "./routes";
import Layout from "./layouts/Layout";
import "./firebaseClient";

function App() {
  return (
    <Layout>
      <AppRoutes />
    </Layout>
  );
}

export default App;
