import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseClient";
import { onAuthStateChanged } from "firebase/auth";
import { createCheckoutSession } from "../stripe/createCheckoutSession";

import { fetchUserSubscription } from "../stripe/helpers";

interface PricingBoxProps {
  pro: boolean;
  name: string;
  isBilledAnnually: boolean;
  price: string;
  emails: string;
  summaries: string;
  priceId: string;
}

const PricingBox: FunctionComponent<PricingBoxProps> = ({
  pro,
  name,
  isBilledAnnually,
  price,
  emails,
  summaries,
  priceId,
}: PricingBoxProps) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [authedUser, setUser] = useState({ uid: "" });
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setIsAuthed(true);
        setUser(user);
      }
    });
  }, [setIsAuthed]);

  const handlePayment = async (priceId: string) => {
    setIsPaymentInProgress(true);

    createCheckoutSession(authedUser!.uid, priceId)
      .then(async () => {})
      .catch(() => {
        toast({
          title: "Payment Failed",
          description: "We failed to process you payment",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      boxShadow="sm"
      p={6}
      rounded="lg"
      bg={pro ? "white" : "white"}
      borderColor={pro ? "brand.500" : "gray.200"}
      backgroundColor={pro ? "brand.50" : "white"}
      borderWidth={2}
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        {isPaymentInProgress && <Spinner size="md" />}
      </Flex>

      <VStack spacing={3} align="flex-start">
        <Text fontWeight={600} casing="uppercase" fontSize="sm">
          {name}
        </Text>
        <Box w="full">
          {isBilledAnnually ? (
            <Text fontSize="3xl" fontWeight="medium">
              ${price}
            </Text>
          ) : (
            <Text fontSize="3xl" fontWeight="medium">
              ${price}
            </Text>
          )}
          <Text fontSize="sm">per month per user</Text>
        </Box>{" "}
        <Text>Compose emails faster with AICompose</Text>
        {name !== "Free" && (
          <VStack>
            <Button
              size="sm"
              onClick={
                isAuthed
                  ? async () => {
                      const plan = await fetchUserSubscription();
                      if (plan && plan === name) {
                        toast({
                          title: "Plan already purchased",
                          description: "You already have this plan",
                          status: "warning",
                          duration: 5000,
                          isClosable: true,
                        });
                      } else if (plan) {
                        toast({
                          title: "Plan already exists",
                          description:
                            "You already have a plan. Please go to your User Profile and cancel your existing subscription to continue.",
                          status: "warning",
                          duration: 5000,
                          isClosable: true,
                        });
                      } else {
                        handlePayment(priceId);
                      }
                    }
                  : () => navigate("/auth", { state: { name, price } })
              }
              colorScheme="brand"
            >
              Get it now →
            </Button>
          </VStack>
        )}
        <VStack pt={8} spacing={4} align="flex-start">
          <Text fontWeight="medium">Features:</Text>
          <List spacing={3}>
            <ListItem>
              <HStack align="flex-start" spacing={1}>
                <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                <Text>
                  Compose emails with ease using the browser extension
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align="flex-start" spacing={1}>
                <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                <Text>
                  Use all the formatting and editing features of Gmail to
                  compose your email
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align="flex-start" spacing={1}>
                <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                <Text>{emails} email generations per day</Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align="flex-start" spacing={1}>
                <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                <Text>{summaries} automated summaries per day</Text>
              </HStack>
            </ListItem>
            {pro && (
              <>
                <ListItem>
                  <HStack align="flex-start" spacing={1}>
                    <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                    <Text>Exclusive deals for early adopters</Text>
                  </HStack>
                </ListItem>
                <ListItem>
                  <HStack align="flex-start" spacing={1}>
                    <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                    <Text>Early Access to new Features</Text>
                  </HStack>
                </ListItem>
              </>
            )}
            {name === "Enterprise" && (
              <ListItem>
                <HStack align="flex-start" spacing={1}>
                  <ListIcon as={CheckCircleIcon} color="brand.500" mt={1} />
                  <Text>Priority Access to Customer Support</Text>
                </HStack>
              </ListItem>
            )}
          </List>
        </VStack>
      </VStack>
    </Box>
  );
};

interface PricingSectionProps {}

export const PricingSection: FunctionComponent<PricingSectionProps> = () => {
  const [isBilledAnnually, setIsBilledAnnually] = useState<boolean>(true);
  return (
    <VStack spacing={10} align="center">
      <ButtonGroup isAttached>
        {/* <Button
          onClick={() => {
            setIsBilledAnnually(true);
          }}
          colorScheme={isBilledAnnually ? "brand" : "gray"}
        >
          Annually (-10%)
        </Button> */}

        <Button
          onClick={() => {
            setIsBilledAnnually(false);
          }}
          colorScheme={"brand"}
        >
          Monthly
        </Button>
      </ButtonGroup>

      <SimpleGrid columns={[1, null, 3]} spacing={10}>
        <PricingBox
          pro={false}
          name="Free"
          isBilledAnnually={isBilledAnnually}
          price={"0.00"}
          emails={"Up to 5"}
          summaries={"Up to 3"}
          priceId=""
        />

        <PricingBox
          pro={true}
          name="Premium"
          isBilledAnnually={isBilledAnnually}
          price={"1.99"}
          emails={"Up to 15"}
          summaries={"Up to 15"}
          priceId={process.env.REACT_APP_PREMIUM_PRICE_ID!}
        />

        <PricingBox
          pro={true}
          name="Enterprise"
          isBilledAnnually={isBilledAnnually}
          price={"4.99"}
          emails={"Unlimited"}
          summaries={"Unlimited"}
          priceId={process.env.REACT_APP_ENTERPRISE_PRICE_ID!}
        />
      </SimpleGrid>
    </VStack>
  );
};

export default PricingSection;
