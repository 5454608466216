import { useState } from "react";
import SignupCard from "./SignupCard";
import LoginCard from "./Logincard";

function AuthCard() {
  const [isLoginClicked, setIsLoginClicked] = useState(false);

  return isLoginClicked ? (
    <LoginCard onLoginClick={() => setIsLoginClicked(false)} />
  ) : (
    <SignupCard onLoginClick={() => setIsLoginClicked(true)} />
  );
}
export default AuthCard;
