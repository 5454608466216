import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseClient";
import {
  fetchUserName,
  fetchUserSubscription,
  signOut,
} from "../stripe/helpers";

interface Props {
  onLoginClick: () => void;
}

export default function Profile({ onLoginClick }: Props) {
  const [user, setUser] = useState({
    displayName: "",
    email: "",
  });

  const [subscription, setSubscription] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscription = async () => {
      const sub = await fetchUserSubscription();
      setSubscription(sub ? sub : "Free");
    };
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        user.displayName = await fetchUserName(user.uid);
        setUser(user);
        fetchSubscription();
      } else {
        navigate("/");
      }
    });
  }, [navigate, setUser]);

  return (
    <Center py={6} height="100vh">
      <Box
        maxW={"500px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"md"}
        overflow={"hidden"}
      >
        <Image
          h={"120px"}
          w={"full"}
          src={
            "https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
          }
          objectFit={"cover"}
        />
        <Flex justify={"center"} mt={-12}>
          <Avatar
            size={"xl"}
            src={
              "https://media.istockphoto.com/id/811610402/photo/portrait-silhouette.jpg?s=612x612&w=0&k=20&c=0XyzoNtp_D2IjSc9WvIfwShkM0EumppV5q97f8ld-j4="
            }
            css={{
              border: "2px solid white",
            }}
          />
        </Flex>
        {user.displayName && user.email && subscription ? (
          <Box p={6}>
            <Stack spacing={0} align={"center"} mb={5}>
              <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                {user.displayName}
              </Heading>
              <Text color={"gray.500"}>{user.email}</Text>
            </Stack>

            <Stack direction={"row"} justify={"center"} spacing={6}>
              <Stack spacing={0} align={"center"}>
                <Text fontWeight={600}>
                  {subscription && subscription.toUpperCase()}
                </Text>
                <Text fontSize={"sm"} color={"gray.500"}>
                  Subscription
                </Text>
              </Stack>
            </Stack>

            <Button
              w={"full"}
              mt={8}
              bg="#151f21"
              color={"white"}
              rounded={"md"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
              onClick={onLoginClick}
            >
              Edit
            </Button>
            <Stack spacing={6} mt={2} direction={["column", "row"]}>
              <Button
                bg={"red.400"}
                color={"white"}
                w="full"
                _hover={{
                  bg: "red.500",
                }}
                onClick={async () => {
                  await signOut();
                  navigate("/");
                }}
              >
                Sign Out
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box p={6}>
            <Spinner size="xl" />
          </Box>
        )}
      </Box>
    </Center>
  );
}
